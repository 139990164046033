import { Suspense } from 'react'
import { Provider } from 'react-redux'
import { persistor, store } from './app/store'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import '@/assets/scss/main.scss'
import ReactDOM from 'react-dom/client'
import './lang/index'
import Loading from './components/common/Loading/Loading'
import { PersistGate } from 'redux-persist/integration/react'

const container = document.getElementById('root')!
const root = ReactDOM.createRoot(container)

root.render(
    <Suspense fallback={<Loading />}>
        <Provider store={store}>
            <PersistGate loading={<Loading />} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </Suspense>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./app/App')
}
