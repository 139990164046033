const purchase = {
    title: 'ポイント購入',
    title_item: {
        1: '所有ポイント',
        2: '購入ポイント',
        3: '支払方法',
        4: {
            title: '支払方法',
            desc: '登録されていません',
        },
    },
    purchase_amount: '購入金額',
    purchase_amount_bottom: '（税込<span class="font-number">{{0}}</span>円）',
    popup_charge_point: {
        title: '購入するポイントを入力',
        desc: '購入は{{0}}point単位になります',
        point: 'point',
    },
    card_element: {
        card_number: 'カード番号',
        date_of_expiry: '有効期限',
        date_of_expiry_desc: '月 / 年',
        security_code: 'セキュリティコード',
        note: '※入力は半角数字のみ（ハイフン、スペースなし）',
        is_payment: '「****{{0}}」のカード',
        stripe_not_found: 'Stripe not found!',
        payment_success: '{{0}}pointを購入しました',
        error: {
            add_card: 'クレジットカードの登録に失敗しました',
            create_payment: '決済に失敗しました',
        },
    },
    package: {
        add_item: '購入するポイントを入力',
    },
    btn: {
        submit: '利用規約に同意して、ポイントを購入する',
        cancel: 'キャンセル',
        service: '利用規約',
    },
    popup_element: {
        title: 'クレジットカード登録',
        ok: '登録する',
        cancel: '閉じる',
    },
    popup_policy: {
        title: '利用規約',
    },
    popup_confirm: {
        title: 'ポイント購入',
        btn_cancel: 'キャンセル',
        btn_ok: '確定',
        content: `  <span class="fz-24 fw-700 font-number">{{0}}</span>
            <span class="fz-18 fw-900">point&nbsp</span>
            <span class="fz-24 fw-700">(</span>
            <span class="fz-24 fw-700 font-number">{{1}}</span>
            <span class="fz-16 fw-900 pt-1 text-top">円</span>
            <span class="fz-24 fw-700">)</span>`,
    },
}
export default purchase
