import dayjs from '@/dayjs'

export const ACCESS_TOKEN = 'access_token'

export const START_YEAR = 1920

export const CURRENT_YEAR = new Date().getFullYear()

export const MONTHS = Array.from({ length: 12 }, (item, i) => {
    return dayjs(new Date(0, i)).format('MM')
})

export const YEARS: number[] | string[] = Array(CURRENT_YEAR - START_YEAR + 1)
    .fill((item: any) => item)
    .map((_, idx) => CURRENT_YEAR - idx)

export const YEARS_START_FROM_2022 = Array(CURRENT_YEAR - 2022 + 1)
    .fill((item: any) => item)
    .map((_, idx) => CURRENT_YEAR - idx)

export const CURRENT_MONTH = dayjs(new Date()).format('MM')

export const CURRENT_DAY = dayjs(new Date()).format('DD')

export const IMAGE_EXTENSIONS = ['jpeg', 'png', 'jpg']

export const AGES = 18

export const HTTP_STATUS = {
    success: 200,
}

export const MAX_SIZE_IMAGE = 10 //MB

export const MAX_SIZE_IMAGE_BYTE = 10485760 //KB

export const GENDERS: any = {
    1: '男性',
    2: '女性',
}

export const DAY_LIST: any = {
    1: '日',
    2: '月',
    3: '火',
    4: '水',
    5: '木',
    6: '金',
    7: '土',
}

export const STATUS: {
    [key: string]: string
} = {
    idle: 'idle',
    loading: 'loading',
    succeeded: 'succeeded',
    failed: 'failed',
}

export const TIMER_DOWN =
    Number(process.env.REACT_APP_TIMER_DOWN) ?? 3 * 60 * 1000 // 3 * 60 * 1000
export const TIMER_RE_QRCODE =
    Number(process.env.REACT_APP_TIMER_RE_QRCODE) ?? 1 * 60 * 1000 // 1 * 60 * 1000

export const TIMER_LOST_ITEM = 1 * 1000
export const IS_FIRST_LOGIN = 1
export const BEFORE_END_TIME = 45
export const TIMER_CLOSE_ETIQUETTE = 30 * 1000

export const TRANSACTION_TYPE_NORMAL = 1
export const TRANSACTION_TYPE_LOST_ITEM = 2

export const BUTTON_DETAIL_SHOP = {
    none: 0,
    default: 1,
    checkTime: 2,
    checkNumberUser: 3,
}

export const HEIGHT_NO_FIX = 700
export const STATUS_ETIQUETTE_DEFAULT = 0
export const REACT_APP_MAIL_CC = String(process.env.REACT_APP_MAIL_CC)
export const EXIT_TYPE = 2
export const IS_DISABLED = 2
export const IS_UNABLE = 1
