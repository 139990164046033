import { ACCESS_TOKEN } from '@/utils/constants'
import axios from 'axios'
import router from '@/router'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.withCredentials = true

console.log('process.env.REACT_APP_API_URL :>> ', process.env.REACT_APP_API_URL)
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

instance.interceptors.request.use(
    function (config: any) {
        try {
            const token = localStorage.getItem(ACCESS_TOKEN)
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`
            }
        } catch (error) {
            throw Error('')
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    function (response) {
        const data = response?.data?.data || response?.data || response
        if (
            response?.data?.status_code === 401 ||
            response?.data?.status_code === 403
        ) {
            localStorage.clear()
        }

        if (response?.data?.status_code !== 200)
            return Promise.reject(response?.data)

        return data
    },
    function (error) {
        if (error?.response?.data.message === 'Unauthenticated.') {
            localStorage.clear()
            return router.navigate('/login')
        }
        if (error?.response?.data) {
            return Promise.reject(error?.response?.data)
        }
        return Promise.reject(error)
    }
)

export const setLocaleApi = (locale: string) => {
    instance.defaults.headers.common['lang'] = locale
}

export default instance
