import React from 'react'
import logo from '@/assets/imgs/sauna_logo.png'
interface HeaderProps {
    title?: string
}

const Header: React.FC<HeaderProps> = ({ title = 'sauna' }) => {
    return (
        <></>
    )
}

export default Header
