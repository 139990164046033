import { App } from 'antd'
import React, { useLayoutEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import AppNav from '../AppNav'
import Header from '../Header'

interface HeaderProps {
    children?: any
}

const Layout: React.FC<HeaderProps> = ({ children }) => {
    const { pathname } = useLocation()
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <App>
            <main id="wrapper">
                <Header title="dts sauna" />
                <Outlet />
            </main>
            <AppNav />
        </App>
    )
}

export default Layout
