import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import * as API from '@/api/shopAPI'
import {
    EntryInfo,
    EntryKey,
    ExitKey,
    LostItemEntryInfo,
    Shop,
    ShopState,
} from '@/interface/shopInterface'

const initialState = {
    shop: {} as Shop,
    favoriteShop: {} as Shop,
    shops: [] as Shop[],
    status: 'idle',
    statusReEntryKey: 'idle',
    statusReExitKey: 'idle',
    statusEntryKey: 'idle',
    statusEntryInfo: 'idle',
    statusExitKey: 'idle',
    statusDetailShop: 'idle',
    error: null,
    entryKey: {} as EntryKey,
    reEntryKey: {} as EntryKey,
    reExitKey: {} as any,
    entryInfo: {
        has_etiquette: false,
        payment_point: 0,
    } as EntryInfo,
    exitKey: {} as ExitKey,
    lostItemEntryKey: {} as EntryKey,
    lostItemEntryInfo: {} as LostItemEntryInfo,
    lostItemExitKey: {} as any,
    statusLostItemEntry: 'idle',
    statusLostItemExit: 'idle',
    rejectEtiquette: false,
    completeEtiquette: false,
    statusCompleteEtiquette: 'idle',
    entryKeyStatus: false,
    statusEntryKeyStatus: 'idle',
} as ShopState

export const listShop = createAsyncThunk(
    'shops/list',
    async (payload: Object | {}) => {
        try {
            const response = await API.shops(payload)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return error.response.data.message
            } else {
                return error.message
            }
        }
    }
)

export const detailShop = createAsyncThunk(
    'shops/detail',
    async (id: string | string[] | number, { rejectWithValue }) => {
        try {
            const response = await API.shop(id)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const entryKey = createAsyncThunk(
    'shops/entryKey',
    async (id: string | string[] | number, { rejectWithValue }) => {
        try {
            const response = await API.entryKey(id)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const entryInfo = createAsyncThunk(
    'shops/entryInfo',
    async (id: string | string[] | number, { rejectWithValue }) => {
        try {
            const response = await API.entryInfo(id)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const exitKey = createAsyncThunk(
    'shops/exitKey',
    async (id: string | string[] | number, { rejectWithValue }) => {
        try {
            const response = await API.exitKey(id)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const reEntry = createAsyncThunk(
    'shops/reEntry',
    async (id: string | string[] | number, { rejectWithValue }) => {
        try {
            const response = await API.reEntryKey(id)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const reExit = createAsyncThunk(
    'shops/reExit',
    async (id: string | string[] | number, { rejectWithValue }) => {
        try {
            const response = await API.reExitKey(id)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const rejectEtiquette = createAsyncThunk(
    'shops/rejectEtiquette',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await API.rejectEtiquette()
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const completeEtiquette = createAsyncThunk(
    'shops/completeEtiquette',
    async (_, { rejectWithValue }) => {
        try {
            const response = await API.completeEtiquette()
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const updateFavoriteShop = createAsyncThunk(
    'shops/favorite-shop',
    async (payload: Object | {}) => {
        try {
            const response = await API.FavoriteShop(payload)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return error.response.data.message
            } else {
                return error.message
            }
        }
    }
)

export const detailFavoriteShop = createAsyncThunk(
    'shops/detailFavorite',
    async (id: string | string[] | number, { rejectWithValue }) => {
        try {
            const response = await API.shop(id)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const lostItemEntry = createAsyncThunk(
    'shops/lostItemEntry',
    async (id: string | string[] | number, { rejectWithValue }) => {
        try {
            const response = await API.lostItemEntry(id)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const lostItemEntryInfoCallApi = createAsyncThunk(
    'shops/lostItemEntryInfo',
    async (id: string | string[] | number, { rejectWithValue }) => {
        try {
            const response = await API.lostItemEntryInfo(id)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const lostItemExit = createAsyncThunk(
    'shops/lostItemExit',
    async (id: string | string[] | number, { rejectWithValue }) => {
        try {
            const response = await API.lostItemExit(id)
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const updateEntryKeyStatus = createAsyncThunk(
    'shops/updateEntryKeyStatus',
    async (_, { rejectWithValue }) => {
        try {
            const response = await API.updateEntryKeyStatus()
            return response
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

const shopsSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setShop: (state, action: PayloadAction<Shop | undefined>) => {
            state.shop = action.payload
        },
        setStatusDetailShop: (state, action: PayloadAction<any>) => {
            state.statusDetailShop = action.payload
        },
        setFavoriteShop: (state, action: PayloadAction<Shop | undefined>) => {
            state.favoriteShop = action.payload
        },
        setStatusEntryKey: (state, action: PayloadAction<any>) => {
            state.statusEntryKey = action.payload
        },
        setStatusReEntryKey: (state, action: PayloadAction<any>) => {
            state.statusReEntryKey = action.payload
        },
        setStatusReExitKey: (state, action: PayloadAction<any>) => {
            state.statusReEntryKey = action.payload
        },
        setStatusEntryInfo: (state, action: PayloadAction<any>) => {
            state.statusEntryInfo = action.payload
        },
        setStatusExitKey: (state, action: PayloadAction<any>) => {
            state.statusExitKey = action.payload
        },
        setStatusLostItemEntry: (state, action: PayloadAction<any>) => {
            state.statusLostItemEntry = action.payload
        },
        setStatusLostItemEntryInfo: (state, action: PayloadAction<any>) => {
            state.statusLostItemEntryInfo = action.payload
        },
        setStatusLostItemExit: (state, action: PayloadAction<any>) => {
            state.statusLostItemExit = action.payload
        },
        setStatusCompleteEtiquette: (state, action: PayloadAction<any>) => {
            state.statusCompleteEtiquette = action.payload
        },
    },
    extraReducers: {
        [listShop.pending as any]: (state: ShopState) => {
            state.status = 'loading'
            state.error = null
        },
        [listShop.fulfilled as any]: (state: ShopState, { payload }) => {
            state.status = 'succeeded'
            state.shops = payload
        },
        [listShop.rejected as any]: (state, { payload }) => {
            state.status = 'failed'
            state.error = payload
        },
        [detailShop.pending as any]: (state: ShopState) => {
            state.statusDetailShop = 'loading'
            state.error = null
        },
        [detailShop.fulfilled as any]: (state: ShopState, { payload }) => {
            state.statusDetailShop = 'succeeded'
            state.shop = payload
        },
        [detailShop.rejected as any]: (state, { payload }) => {
            state.statusDetailShop = 'failed'
            state.error = payload
        },
        [updateFavoriteShop.fulfilled as any]: (state: ShopState) => {
            state.status = 'succeeded'
        },
        [detailFavoriteShop.fulfilled as any]: (
            state: ShopState,
            { payload }
        ) => {
            state.status = 'succeeded'
            state.favoriteShop = payload
        },
        [reEntry.pending as any]: (state: any) => {
            state.statusReEntryKey = 'loading'
            state.error = null
        },
        [reEntry.fulfilled as any]: (state: ShopState, { payload }) => {
            state.statusReEntryKey = 'succeeded'
            state.reEntryKey = payload
        },
        [reEntry.rejected as any]: (state, { payload }) => {
            state.statusReEntryKey = 'failed'
            state.error = payload
        },
        [reExit.pending as any]: (state: any) => {
            state.statusReExitKey = 'loading'
            state.error = null
        },
        [reExit.fulfilled as any]: (state: ShopState, { payload }) => {
            state.statusReExitKey = 'succeeded'
            state.reExitKey = payload
        },
        [reExit.rejected as any]: (state, { payload }) => {
            state.statusReExitKey = 'failed'
            state.error = payload
        },
        [entryKey.pending as any]: (state: any) => {
            state.statusEntryKey = 'loading'
            state.error = null
        },
        [entryKey.fulfilled as any]: (state: ShopState, { payload }) => {
            state.statusEntryKey = 'succeeded'
            state.entryKey = payload
        },
        [entryKey.rejected as any]: (state, { payload }) => {
            state.statusEntryKey = 'failed'
            state.error = payload
        },
        [entryInfo.pending as any]: (state: any) => {
            state.statusEntryInfo = 'loading'
            state.error = null
        },
        [entryInfo.fulfilled as any]: (state: ShopState, { payload }) => {
            state.statusEntryInfo = 'succeeded'
            state.entryInfo = payload
        },
        [entryInfo.rejected as any]: (state, { payload }) => {
            state.statusEntryInfo = 'failed'
            state.error = payload
        },
        [exitKey.pending as any]: (state: any) => {
            state.statusExitKey = 'loading'
            state.error = null
        },
        [exitKey.fulfilled as any]: (state: ShopState, { payload }) => {
            state.statusExitKey = 'succeeded'
            state.exitKey = payload
        },
        [exitKey.rejected as any]: (state, { payload }) => {
            state.statusExitKey = 'failed'
            state.error = payload
        },
        [rejectEtiquette.fulfilled as any]: (state: ShopState, { payload }) => {
            state.rejectEtiquette = payload
        },
        [completeEtiquette.pending as any]: (state: any) => {
            state.statusCompleteEtiquette = 'loading'
            state.error = null
        },
        [completeEtiquette.fulfilled as any]: (
            state: ShopState,
            { payload }
        ) => {
            state.statusCompleteEtiquette = 'succeeded'
            state.completeEtiquette = payload
        },
        [completeEtiquette.rejected as any]: (state, { payload }) => {
            state.statusCompleteEtiquette = 'failed'
            state.error = payload
        },
        [lostItemEntry.pending as any]: (state: any) => {
            state.statusLostItemEntry = 'loading'
            state.error = null
        },
        [lostItemEntry.fulfilled as any]: (state: ShopState, { payload }) => {
            state.statusLostItemEntry = 'succeeded'
            state.lostItemEntryKey = payload
        },
        [lostItemEntry.rejected as any]: (state, { payload }) => {
            state.statusLostItemEntry = 'failed'
            state.error = payload
        },
        [lostItemEntryInfoCallApi.pending as any]: (state: any) => {
            state.statusLostItemEntryInfo = 'loading'
            state.error = null
        },
        [lostItemEntryInfoCallApi.fulfilled as any]: (
            state: ShopState,
            { payload }
        ) => {
            state.statusLostItemEntryInfo = 'succeeded'
            state.lostItemEntryInfo = payload
        },
        [lostItemEntryInfoCallApi.rejected as any]: (state, { payload }) => {
            state.statusLostItemEntryInfo = 'failed'
            state.error = payload
        },
        [lostItemExit.pending as any]: (state: any) => {
            state.statusLostItemExit = 'loading'
            state.error = null
        },
        [lostItemExit.fulfilled as any]: (state: ShopState, { payload }) => {
            state.statusLostItemExit = 'succeeded'
            state.lostItemExitKey = payload
        },
        [lostItemExit.rejected as any]: (state, { payload }) => {
            state.statusLostItemExit = 'failed'
            state.error = payload
        },
        [updateEntryKeyStatus.pending as any]: (state: any) => {
            state.statusEntryKeyStatus = 'loading'
            state.error = null
        },
        [updateEntryKeyStatus.fulfilled as any]: (
            state: ShopState,
            { payload }
        ) => {
            state.statusEntryKeyStatus = 'succeeded'
            state.entryKeyStatus = payload
        },
        [updateEntryKeyStatus.rejected as any]: (state, { payload }) => {
            state.statusEntryKeyStatus = 'failed'
            state.error = payload
        },
    },
})

export const {
    setShop,
    setStatusDetailShop,
    setFavoriteShop,
    setStatusEntryKey,
    setStatusReEntryKey,
    setStatusReExitKey,
    setStatusExitKey,
    setStatusEntryInfo,
    setStatusLostItemEntry,
    setStatusLostItemExit,
    setStatusLostItemEntryInfo,
    setStatusCompleteEtiquette,
} = shopsSlice.actions

export default shopsSlice.reducer

export const shopList = (state: any) => state.shop.shops as Shop[]

export const selectShop = (state: any) => state.shop.shop as Shop

export const favoriteShop = (state: any) => state.shop.favoriteShop as Shop

export const qrCode = (state: any) => state.shop.entryKey as EntryKey

export const entryInfoList = (state: any) => state.shop.entryInfo as EntryInfo

export const exitKeyData = (state: any) => state.shop.exitKey as ExitKey

export const lostItemEntryData = (state: any) =>
    state.shop.lostItemEntryKey as EntryKey

export const lostItemEntryInfoData = (state: any) =>
    state.shop.lostItemEntryInfo as LostItemEntryInfo

export const lostItemExitData = (state: any) =>
    state.shop.lostItemExitKey as ExitKey

export const completeEtiquetteData = (state: any) =>
    state.shop.completeEtiquette as Boolean
